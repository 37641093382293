import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.form,
    ref: "form",
    inline: true,
    size: "medium"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Enable Products Auto-Sync:" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_switch, {
            "active-color": "#13ce66",
            modelValue: _ctx.setting.syncProduct,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.setting.syncProduct = $event)),
            loading: _ctx.isLoading
          }, null, 8, ["modelValue", "loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}