
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import { ElMessage } from "element-plus";

@Options({
  components: {},
  props: {
    setting: {},
  },
})
export default class SettingCatalogPage extends Vue {
  form = {};
  isLoading = false;
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  data() {
    return {
      languages: [
        {
          value: "en",
          label: "English",
        },
        {
          value: "es",
          label: "Español",
        },
        {
          value: "fr",
          label: "Français",
        },
      ],
      value: "",
    };
  }
  beforeChange = () => {
    this.isLoading = true;
    return new Promise((resolve) => {
      setTimeout(() => {
        this.isLoading = false;
        return resolve(true);
      }, 1000);
    });
  };
  async update(language: string) {
    await axios
      .post(
        "/user/setting/language",
        { language: language },
        {
          headers: this.headers,
          params: {
            account: localStorage.getItem("account"),
          },
        }
      )
      .then(function (response) {
        console.log(response);
        ElMessage.success("Save successfully !!!");
      })
      .catch(function (error) {
        ElMessage.error("Something wrong !!!");
        console.log(error);
      });
  }
}
