
import { Options, Vue } from "vue-class-component";
import ChildSetting from "./ChildSetting.vue";
import General from "../components/General.vue";
import Catalog from "../components/Catalog.vue";
import Crons from "../components/Crons.vue";
import Sale from "../components/Sale.vue";
import { ElMessage } from "element-plus";
import axios from "@/plugins/axios";

interface Result {
  data: [];
}
@Options({
  components: {
    ChildSetting,
    General,
    Catalog,
    Sale,
    Crons,
  },
})
export default class SettingPage extends Vue {
  tabs = [
    // {
    //   pathName: "General",
    // },
    // {
    //   pathName: "Catalog",
    // },
    // {
    //   pathName: "Sale",
    // },
    {
      pathName: "Crons",
    },
  ];
  value: any;
  show = true;
  originalSettingList = Object.create(null);
  settingList = Object.create(null);
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  async created() {
    // this.getListSetting();
  }
  log() {
    if (this.value == 1) {
      this.show = false;
    } else this.show = true;
  }

  get isChanged() {
    return (
      JSON.stringify(this.originalSettingList) !=
      JSON.stringify(this.settingList)
    );
  }
  async saveSettings() {
    await this.$store.dispatch("setLoading", true, { root: true });

    await axios
      .post(
        "/user/setting/save",
        {
          syncOrder: this.settingList.syncOrder,
          syncProduct: this.settingList.syncProduct,
          language: this.settingList.language,
        },
        {
          headers: this.headers,
          params: {
            account: localStorage.getItem("account"),
          },
        }
      )
      .then(() => {
        this.originalSettingList = { ...this.settingList };
        ElMessage.success("Save successfully !!!");
      })
      .catch(function (error) {
        ElMessage.error("Something wrong !!!");
        console.log(error);
      });
    await this.$store.dispatch("setLoading", false, { root: true });
  }
  async getListSetting() {
    await this.$store.dispatch("setLoading", true, { root: true });
    let res: Result = {
      data: [],
    };
    res = await axios.get("user/setting", {
      headers: this.headers,
      params: {
        account: localStorage.getItem("account"),
      },
    });
    if (res) {
      this.settingList = res.data;
      this.originalSettingList = { ...this.settingList };
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
}
