<template>
  <el-row type="flex">
    <el-col :span="10">
      <!-- <div class="title">
        <h3>{{ child.pathName }}</h3>
      </div> -->
    </el-col>
  </el-row>
  <el-divider></el-divider>
</template>
<script>
export default {
  props: {
    child: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>

h4 {
  font-size: 1.5rem;
  margin: 0;
}
.title {
  text-align: start;
}
</style>