import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChildSetting = _resolveComponent("ChildSetting")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createBlock(_component_el_tabs, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.value = $event)),
    onTabClick: _ctx.log,
    class: "tab",
    "tab-position": "left"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createBlock(_component_el_tab_pane, {
          key: tab,
          label: tab.pathName
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ChildSetting, { child: tab }, null, 8, ["child"]),
            (_openBlock(), _createBlock(_resolveDynamicComponent(tab.pathName), { setting: _ctx.settingList }, null, 8, ["setting"]))
          ]),
          _: 2
        }, 1032, ["label"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "onTabClick"]))
}